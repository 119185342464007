<template>

  <!-- Dialog Add Direct Cost -->
  <ib-dialog
    id="dialog-story-mode-add-direct-cost"
    :visible="dialogAddDirectCostVisible"
    fullscreen
    @open="openDialog"
    @close="closeDialog"
    @closed="closedDialog"
  >
    <!-- Mobile title -->
    <template #title>
      <ib-dialog-container class="d-md-none">
        <h2>
          {{ $tc('addDirectCost') }}
        </h2>
      </ib-dialog-container>
    </template>
    <!-- /Mobile title -->

    <!-- Content Wrapper -->
    <ib-dialog-container class="content-wrapper">
      <!-- Header -->
      <add-dialog-header>
        <template #left>
          <!-- Title -->
          <h2 class="m-0">
            {{ $tc('addDirectCost') }}
          </h2>
          <!-- /Title -->
        </template>

      </add-dialog-header>
      <!-- /Header -->

      <!-- Info Guide -->
      <info-guide-add-dialog
        class="mb-3"
        :visible="guideVisible"
        :title="$t('pages.businessGuide.directCosts.addDialog.guide.title')"
        :text="$t('pages.businessGuide.directCosts.addDialog.guide.text')"
        @click="onToggleDialogGuide"
      />
      <!-- Info Guide -->

      <!-- Ai Buddy -->
      <ai-buddy type="direct-costs" class="ai-buddy-margin-bottom" />
      <!-- Ai Buddy -->

      <!-- Inner Wrapper -->
      <div class="inner-wrapper">
        <div class="grid-wrapper">
          <el-row :gutter="60">

            <!-- Left Side -->
            <el-col :md="12">

              <transition name="fade" />

              <!-- Form Wrapper -->
              <div class="direct-form-wrapper">

                <!-- Form Inner -->
                <div class="direct-form-inner-top">

                  <!-- Form -->
                  <el-form :model="form">

                    <!-- Direct Cost Revenue Streams -->
                    <el-form-item
                      class="input-inside-label revenue-stream" :label="$t('pages.businessGuide.directCosts.addDialog.fields.directCostOfRevenueStream')"
                      :class="{'focused' : focus.revenueStreamId }"
                    >

                      <!-- Direct Cost Select -->
                      <ib-select
                        v-model="form.revenueStreamId"
                        :popper-append-to-body="false"
                        popper-class="promo-dropdown"
                        placeholder=" "
                        @focus="toggleFocus('revenueStreamId')"
                        @blur="toggleFocus('revenueStreamId')"
                        @change="changeRevenueStream"
                      >
                        <ib-option
                          v-for="revenueStream in revenueStreams"
                          :key="revenueStream.id"
                          :label="revenueStream.name"
                          :value="revenueStream.id"
                          :class="{ 'selected': revenueStream.id === form.revenueStreamId }"
                        />
                      </ib-select>
                      <!-- /Direct Cost Select -->

                    </el-form-item>
                    <!-- /Direct Cost Revenue Streams -->

                    <!-- Name -->
                    <ib-input
                      v-model="form.name"
                      :label="$t('fields.name')"
                    />
                    <!-- /Name -->

                    <!-- Switch Wrapper -->
                    <div class="switch-wrapper">

                      <!-- Switch -->
                      <form-switcher
                        v-model="form.isConstantAmount"
                        :options="[{
                          title: $t('constantAmount'),
                          value: true,
                          callback: changeToConstantAmount
                        },{
                          title: $t('percentOfRevenue'),
                          value: false,
                          callback: changeToPercentageOfRevenue
                        }]"
                      />
                      <!-- /Switch -->

                      <!-- Percentage of Revenue Input -->
                      <el-form-item
                        v-show="!form.isConstantAmount"
                        :class="{ 'focused': focus.percentageOfRevenue }"
                        class="currency-related-form-item"
                        :label="$t('pages.businessGuide.directCosts.addDialog.fields.percentage')"
                      >
                        <div class="currency-related-input percentage el-input">
                          <currency-input
                            v-model="form.percentageOfRevenue"
                            class="el-input__inner"
                            :distraction-free="false"
                            :currency="null"
                            :value-range="{min: 0, max: 100}"
                            :precision="{min: 0, max: 2}"
                            locale="en-US"
                          />
                        </div>
                        <span class="currency-symbol">%</span>
                      </el-form-item>
                      <!-- /Percentage of Revenue -->

                      <!-- Constant Amount Block -->
                      <div v-show="form.isConstantAmount" class="group">

                        <!-- Amount Input -->
                        <el-form-item
                          class="input-inside-label" :label="`${$t('fields.amount')} (${currencySymbol})`"
                          :class="{'focused': focus.amount}"
                        >
                          <div class="input-with-select el-input">
                            <currency-input
                              v-model="form.amount"
                              class="el-input__inner"
                              :distraction-free="false"
                              :currency="null"
                              :value-range="{min: 0, max: 999999999}"
                              :precision="{min: 0, max: 2}"
                              locale="en-US"
                              @focus="toggleFocus('amount')"
                              @blur="toggleFocus('amount')"
                            />
                          </div>
                        </el-form-item>
                        <!-- /Amount Input -->

                        <!-- Period Select -->
                        <el-form-item
                          class="input-inside-label"
                          label=""
                          :class="{'focused' : focus.constantAmountInterval === true}"
                        >
                          <ib-select
                            ref=""
                            v-model="form.constantAmountInterval"
                            :popper-append-to-body="false"
                            popper-class="promo-dropdown"
                            placeholder="Paid Monthly"
                            @focus="toggleFocus('constantAmountInterval')"
                            @blur="toggleFocus('salesVolumeInterval')"
                          >
                            <ib-option :label="$t('monthly')" value="Monthly" />
                            <ib-option :label="$t('quarterly')" value="Quarterly" />
                            <ib-option :label="$t('annually')" value="Annually" />
                          </ib-select>
                        </el-form-item>
                        <!-- /Period Select -->

                      </div>
                      <!-- /Constant Amount Block -->

                      <!-- Date Picker -->
                      <div class="group date">

                        <!-- Date Start -->
                        <el-form-item
                          class="input-inside-label yellow"
                          :label="$t('starts')"
                          :class="{ 'focused': focus.starts === true }"
                        >
                          <el-date-picker
                            v-model="revenueStarts"
                            popper-class="date-picker-monthly-dropdown-modal"
                            :popper-append-to-body="false"
                            :picker-options="pickerOptionsStarts"
                            class="date-picker-monthly"
                            type="month"
                            :clearable="false"
                            :disabled="!form.isConstantAmount"
                            format="MMMM, yyyy"
                            value-format="yyyy-MM-dd"
                            @blur="toggleFocus('starts')"
                            @change="toggleFocus('starts')"
                            @focus="toggleFocus('starts')"
                          />
                        </el-form-item>
                        <!-- /Date Start -->

                        <!-- Date End -->
                        <el-form-item
                          class="input-inside-label yellow"
                          :label="$t('ends')"
                          :class="{ 'focused': focus.ends === true }"
                        >
                          <el-date-picker
                            v-model="revenueEnds"
                            :clearable="false"
                            :editable="false"
                            :disabled="!form.isConstantAmount"
                            :popper-append-to-body="false"
                            :picker-options="pickerOptionsEnds"
                            format="MMMM, yyyy"
                            class="date-picker-monthly"
                            popper-class="date-picker-monthly-dropdown-modal ends2"
                            type="month"
                            value-format="yyyy-MM-dd"
                            @blur="toggleFocus('ends')"
                            @change="toggleFocus('ends')"
                            @focus="toggleFocus('ends')"
                          />
                        </el-form-item>
                        <!-- /Date End -->

                      </div>
                      <!-- /Date Picker -->

                    </div></el-form>
                  <!-- Form -->

                </div>
                <!-- Form Inner -->

              </div>
              <!-- /Form Wrapper -->

              <!-- Save & Close -->
              <div class="my-4 my-md-0">

                <!-- Divider -->
                <ib-divider block class="my-4" />
                <!-- /Divider -->

                <el-row :gutter="10" class="d-flex">
                  <el-col :span="12">
                    <!-- Close -->
                    <ib-button class="w-100" variant="white" size="lg" font-family="regular" @click="closeDialogOnButton">
                      {{ $t('close') }}
                    </ib-button>
                    <!-- /Close -->
                  </el-col>

                  <el-col :span="12">
                    <ib-button
                      class="w-100 text-uppercase"
                      size="lg"
                      :disabled="!isAddEnabled"
                      :loading="loading"
                      @click="saveDirectCost"
                    >
                      {{ form.id === 0 ? $t('add') : $t('save') }}
                    </ib-button>
                  </el-col>
                </el-row>
              </div>
              <!-- /Save & Close -->
            </el-col>
            <!-- /Left Side -->

            <!-- Right Side -->
            <el-col :md="12">

              <add-dialog-entry-header>
                <template #left>
                  <h2>{{ $tc('directCost', 2) }}</h2>
                </template>
                <template #right>
                  <erase-icon-button
                    v-if="removeButtonVisible"
                    confirm
                    :loading="removeLoadingExamples"
                    @confirm="deleteExamples"
                  />
                </template>
              </add-dialog-entry-header>

              <!-- Costs Added -->
              <div ref="cardHolder" class="costs-added">

                <!-- Costs Added List -->
                <div
                  v-for="directCost in $store.state.idea.storyMode.forecast.directCosts.directCosts"
                  :key="directCost.id"
                >
                  <set-up-and-projections-card
                    class="mb-2"
                    :entity="directCost"
                    :name="directCost.name"
                    :date="`${getFormattedDate(directCost.starts)} - ${getFormattedDate(directCost.ends)}`"
                    :amount="getFormattedAmount(directCost.average, 2)"
                    :frequency="$t('pages.businessGuide.monthlyAverage')"
                    type="direct-cost"
                    view="list"
                    :delete-disabled="form.id === directCost.id"
                    :edit-disabled="form.id === directCost.id"
                    @edit="onEditDirectCost"
                    @copy="onCopyDirectCost"
                    @delete="onDeleteDirectCost"
                  />
                </div>
                <!-- /Costs Added List -->

              </div>
              <!-- /Costs Added -->

            </el-col>
            <!-- /Right Side -->

          </el-row>
        </div>
      </div>
      <!-- /Inner Wrapper -->
    </ib-dialog-container>
    <!-- /Content Wrapper -->

  </ib-dialog>
  <!-- /Dialog Add Direct Cost -->

</template>

<script>
import AddDialogEntryHeader from '@/components/_v2/Common/AddDialogEntryHeader'
import AddDialogHeader from '@/components/_v2/Common/AddDialogHeader'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import FormSwitcher from '@/components/_v2/Form/FormSwitcher'
import IbOption from '@/components/_v2/Select/IbOption'
import InfoGuideAddDialog from '@/views/Home/StoryMode/Components/Guide/InfoGuideAddDialog.vue'
import MixinCharts from '@/mixins/charts'
import MixinCloseMenu from '@/mixins/closeDropdownMenuOnScroll'
import MixinCurrencies from '@/mixins/currencies'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinDialog from '@/mixins/dialog'
import MixinDirectCosts from '@/mixins/directCosts'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinRevenueStreams from '@/mixins/revenueStreams'
import SetUpAndProjectionsCard from '@/views/Home/StoryMode/Components/Cards/SetUpAndProjectionsCard'

import { mapActions, mapGetters } from 'vuex'
import AiBuddy from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddy.vue'

export default {
  name: 'DialogAddDirectCost',

  components: {
    AiBuddy,
    InfoGuideAddDialog,
    EraseIconButton,
    AddDialogEntryHeader,
    AddDialogHeader,
    FormSwitcher,
    IbOption,
    SetUpAndProjectionsCard
  },

  mixins: [
    MixinCharts,
    MixinCurrencies,
    MixinDialog,
    MixinDirectCosts,
    MixinRevenueStreams,
    MixinGuide,
    MixinCloseMenu,
    MixinDeleteIdeaExamples,
    MixinIdeaRoles
  ],

  props: {
    dialogAddDirectCostVisible: Boolean,
    directCostAction: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      loading: false,
      removeLoadingExamples: false,
      guideVisible: false,
      amountRevenue: 0,
      form: {
        id: 0,
        ideaId: this.$store.state.idea.id,
        revenueStreamId: null,
        revenueStreamName: '',
        name: '',
        type: '',
        amount: null,
        isConstantAmount: true,
        percentageOfRevenue: null,
        constantAmountInterval: 'Monthly',
        starts: this.$store.state.idea.ideaSettings.startingFrom,
        ends: this.$moment(this.$store.state.idea.ideaSettings.startingFrom).add(this.$store.state.idea.ideaSettings.planningFor, 'Y').subtract(1, 'M').format('YYYY-MM-DD'),
        revenueStarts: '',
        revenueEnds: ''
      },
      focus: {
        type: false,
        revenueStreamId: false,
        percentageOfRevenue: false,
        constantAmountInterval: false,
        amount: false,
        starts: false,
        ends: false
      },
      valueField: '',
      guideDialogVisible: false,
      expandedDialogGuide: true
    }
  },

  computed: {
    ...mapGetters('idea', ['getHasItemsFromTemplate']),

    isAddEnabled () {
      let enabled = this.form.revenueStreamId && this.form.name && this.form.amount && this.form.ends && this.form.starts && this.form.constantAmountInterval

      if (!this.form.isConstantAmount) {
        enabled = this.form.revenueStreamId && this.form.name && this.form.percentageOfRevenue && this.form.ends && this.form.starts
      }

      return enabled
    },

    revenueStreams () {
      return this.$store.state.idea.storyMode.forecast.revenueStreams.revenueStreams
    },

    isDialogOpen () {
      return this.dialogAddDirectCostVisible
    },

    revenueStarts: {
      get () {
        return !this.form.isConstantAmount ? this.form.revenueStarts : this.form.starts
      },
      set (newValue) {
        if (!this.form.isConstantAmount) {
          this.form.revenueStarts = newValue
        } else {
          this.form.starts = newValue
        }
      }
    },

    revenueEnds: {
      get () {
        return !this.form.isConstantAmount ? this.form.revenueEnds : this.form.ends
      },
      set (newValue) {
        if (!this.form.isConstantAmount) {
          this.form.revenueEnds = newValue
        } else {
          this.form.ends = newValue
        }
      }
    },

    pickerOptionsStarts () {
      const maxOccurDate = this.form.ends ? this.$moment(this.form.ends).format('x') : this.$moment(this.$store.state.idea.ideaSettings.startingFrom).add(this.$store.state.idea.ideaSettings.planningFor, 'Y').format('x')
      const minOccurDate = this.$moment(this.$store.state.idea.ideaSettings.startingFrom).format('x')

      return {
        disabledDate (time) {
          return time.getTime() < minOccurDate || time.getTime() >= maxOccurDate
        }
      }
    },

    pickerOptionsEnds () {
      const maxOccurDate = this.$moment(this.$store.state.idea.ideaSettings.startingFrom).add(this.$store.state.idea.ideaSettings.planningFor, 'Y').format('x')
      const minOccurDate = this.form.starts ? this.$moment(this.form.starts).add(1, 'M').format('x') : this.$moment(this.$store.state.idea.ideaSettings.startingFrom).format('x')

      return {
        disabledDate (time) {
          return time.getTime() < minOccurDate || time.getTime() >= maxOccurDate
        }
      }
    },

    removeButtonVisible () {
      return this.canEdit && this.getHasItemsFromTemplate('getDirectCosts')
    }
  },

  watch: {
    'form.isConstantAmount': function () {
      this.setDirectCostPeriod()
    },

    'form.revenueStreamId': function () {
      this.setDirectCostPeriod()
    }
  },

  methods: {
    ...mapActions('idea', [
      'deleteStoryModeDirectCost'
    ]),

    closeDialogOnButton () {
      this.$emit('close-dialog-on-button')
    },

    closeDialog () {
      this.$emit('close-dialog-add-direct-cost')
    },

    closedDialog () {
      this.resetForm()
      this.guideVisible = false
    },

    openDialog () {
      this.openGuideDialog('addDirectCostGuide')
      if (this.directCostAction) {
        this.copyDirectCostToForm(this.directCostAction)
        this.form = Object.assign({}, this.directCostAction)
        this.form.ideaId = this.$store.state.idea.id
        this.revenueStreamId = this.directCostAction.revenueStreamId
      }
      this.checkInputLabels()
    },

    toggleDialogGuide () {
      this.guideVisible = !this.guideVisible
    },

    toggleFocus (input) {
      this.focus[input] = !this.focus[input]

      if (this.form[input] !== null && this.form[input] !== '') {
        this.focus[input] = true
      }
    },

    resetForm () {
      this.form = {
        id: 0,
        ideaId: this.$store.state.idea.id,
        name: '',
        type: '',
        revenueStreamId: null,
        typeGroup: '',
        amount: null,
        isConstantAmount: true,
        percentageOfRevenue: null,
        constantAmountInterval: 'Monthly',
        starts: this.$store.state.idea.ideaSettings.startingFrom,
        ends: this.$moment(this.$store.state.idea.ideaSettings.startingFrom).add(this.$store.state.idea.ideaSettings.planningFor, 'Y').subtract(1, 'M').format('YYYY-MM-DD'),
        revenueStarts: '',
        revenueEnds: ''
      }
      this.focus = {
        type: false,
        revenueStreamId: false,
        percentageOfRevenue: false,
        constantAmountInterval: false,
        amount: false,
        starts: false,
        ends: false
      }
      this.checkInputLabels()
    },

    resetGuide () {
      if (this.$store.getters['idea/getDirectCosts'].length === 0) {
        this.guideVisible = false
      }
    },

    saveDirectCost () {
      this.loading = true
      if (this.form.id === 0) {
        this.addDirectCost()
      } else {
        this.updateDirectCost()
      }
    },

    addDirectCost () {
      if (!this.form.isConstantAmount) {
        this.form.starts = this.form.revenueStarts
        this.form.ends = this.form.revenueEnds
      }

      this.$http.post('story-mode/forecast/direct-costs/direct-costs', this.form)
        .then((response) => {
          if (response.status === 201) {
            this.resetGuide()
            this.$store.commit('idea/addDirectCost', response.data.payload.directCost)
            this.resetForm()
          }
          this.$emit('on-update-direct-streams-view')
        }).finally(() => {
          this.loading = false
        })
    },

    updateDirectCost () {
      if (!this.form.isConstantAmount) {
        this.form.starts = this.form.revenueStarts
        this.form.ends = this.form.revenueEnds
      }

      this.$http.put(`story-mode/forecast/direct-costs/direct-costs/${this.form.id}`, this.form)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit('idea/updateDirectCost', response.data.payload.directCost)
            this.resetForm()
          }
          this.$emit('on-update-direct-streams-view')
        }).finally(() => {
          this.loading = false
        })
    },

    onEditDirectCost (directCost) {
      this.copyDirectCostToForm(directCost)
    },

    onCopyDirectCost (directCost) {
      this.copyDirectCostToForm(directCost)
      this.form.id = 0
    },

    onDeleteDirectCost (directCost) {
      this.deleteStoryModeDirectCost(directCost.id)
        .then((response) => {
          this.$emit('on-update-direct-streams-view')
        })
    },

    copyDirectCostToForm (directCost) {
      this.resetForm()
      this.form = Object.assign({}, directCost)
      this.form.ideaId = this.$store.state.idea.id
      this.revenueStreamId = directCost.revenueStreamId
      if (this.form.revenueStreamId && !this.form.isConstantAmount) {
        const revenueStreamObject = this.getRevenueStream(this.form.revenueStreamId)

        this.form.revenueStarts = this.$moment(revenueStreamObject.starts).format('YYYY-MM-DD')
        this.form.revenueEnds = this.$moment(revenueStreamObject.ends).format('YYYY-MM-DD')
      }
      this.checkInputLabels()
      this.scrollToTop()
    },

    changeRevenueStream (value) {
      for (let i = 0; i < this.revenueStreams.length; i++) {
        if (this.revenueStreams[i].id === value) {
          this.form.name = 'Cost of ' + this.revenueStreams[i].name
          // this.amountRevenue = this.revenueStreams[i].totalAmount
        }
      }
      this.checkInputLabels()
    },

    checkInputLength (input) {
      this.focus[input] = !!this.form[input]
    },

    checkInputLabels () {
      this.checkInputLength('type')
      this.checkInputLength('amount')
      this.checkInputLength('constantAmountInterval')
      this.checkInputLength('percentageOfRevenue')
      this.checkInputLength('revenueStreamId')
      this.checkInputLength('starts')
      this.checkInputLength('ends')
    },

    getFormattedDate (date) {
      return this.$moment(date).format('MMM YYYY')
    },

    onToggleDialogGuide () {
      this.guideVisible = !this.guideVisible
    },

    revenueStreamName (value, revenueStreamId) {
      let revenueName = ''
      for (let i = 0; i < this.revenueStreams.length; i++) {
        if (this.revenueStreams[i].id === revenueStreamId) {
          revenueName = this.revenueStreams[i].name
        }
      }

      return revenueName
    },

    getRevenueStream (revenueStreamId) {
      const index = this.$store.state.idea.storyMode.forecast.revenueStreams.revenueStreams.indexOf(
        this.$store.state.idea.storyMode.forecast.revenueStreams.revenueStreams.find(revenueStreamToFind => revenueStreamToFind.id === revenueStreamId)
      )

      return this.$store.state.idea.storyMode.forecast.revenueStreams.revenueStreams[index]
    },

    changeToConstantAmount () {
      this.form.percentageOfRevenue = null
      this.checkInputLabels()
    },

    changeToPercentageOfRevenue () {
      this.form.amount = null
      this.form.constantAmountInterval = 'Monthly'
      this.checkInputLabels()
    },

    setDirectCostPeriod () {
      if (this.form.revenueStreamId && !this.form.isConstantAmount) {
        const revenueStreamObject = this.getRevenueStream(this.form.revenueStreamId)

        this.form.revenueStarts = this.$moment(revenueStreamObject.starts).format('YYYY-MM-DD')
        this.form.revenueEnds = this.$moment(revenueStreamObject.ends).format('YYYY-MM-DD')
      }
    },

    async deleteExamples () {
      try {
        this.removeLoadingExamples = true
        const response = await this.deleteIdeaExamples('direct-costs')
        this.$emit('on-update-direct-streams-view')
        const { type, ids } = response
        this.deleteFromState({ type, ids })
        this.resetForm()
        this.removeLoadingExamples = false
      } catch (error) {
        this.removeLoadingExamples = false
      }
    }
  }
}
</script>
