<template>
  <el-form-item class="input-inside-label yellow focused" :label="label" prop="date.value">
    <el-date-picker
      v-model="content"
      :clearable="false"
      :editable="false"
      :disabled="disabled"
      :picker-options="pickerOptions"
      :popper-append-to-body="false"
      format="MMMM, yyyy"
      class="date-picker-monthly"
      popper-class="date-picker-monthly-dropdown-modal"
      type="month"
      value-format="yyyy-MM-dd"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
    />
  </el-form-item>
</template>

<script>
export default {
  name: 'AddMarketingBudgetDatepicker',

  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      required: true,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    pickerOptions: {
      type: Object,
      required: true
    }
  },

  computed: {
    content: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
