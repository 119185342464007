<template>
  <!-- Dialog Add Financing -->
  <div>
    <ib-dialog
      id="dialog-story-mode-add-financing"
      :visible="dialogAddFinancingVisible"
      fullscreen
      @open="openDialog"
      @close="closeDialog"
      @closed="closedDialog"
    >
      <!-- Mobile title -->
      <template #title>
        <ib-dialog-container class="d-md-none">
          <h2>
            {{ $tc('addFinancingOption') }}
          </h2>
        </ib-dialog-container>
      </template>
      <!-- /Mobile title -->

      <!-- Content Wrapper -->
      <ib-dialog-container class="content-wrapper">
        <!-- Header -->
        <add-dialog-header>
          <template #left>
            <!-- Title -->
            <h2 class="m-0">
              {{ $tc('addFinancingOption') }}
            </h2>
            <!-- /Title -->
          </template>
        </add-dialog-header>
        <!-- /Header -->

        <!-- Info Guide -->
        <info-guide-add-dialog
          v-if="form.type === ''"
          class="mb-5"
          :visible="guideVisible"
          :title="$t('pages.businessGuide.financing.addDialog.guide.default.title')"
          :text="$t('pages.businessGuide.financing.addDialog.guide.default.text')"
          @click="onToggleDialogGuide"
        />
        <!-- Info Guide -->

        <!-- Info Guide -->
        <info-guide-add-dialog
          v-if="form.type === 'savings'"
          class="mb-5"
          :visible="guideVisible"
          :title="$t('pages.businessGuide.financing.addDialog.guide.savings.title')"
          :text="$t('pages.businessGuide.financing.addDialog.guide.savings.text')"
          @click="onToggleDialogGuide"
        />
        <!-- Info Guide -->

        <!-- Info Guide -->
        <info-guide-add-dialog
          v-if="form.type === 'crowdfunding'"
          class="mb-5"
          :visible="guideVisible"
          :title="$t('pages.businessGuide.financing.addDialog.guide.crowdfunding.title')"
          :text="$t('pages.businessGuide.financing.addDialog.guide.crowdfunding.text')"
          @click="onToggleDialogGuide"
        />
        <!-- Info Guide -->

        <!-- Info Guide -->
        <info-guide-add-dialog
          v-if="form.type === 'investment'"
          class="mb-5"
          :visible="guideVisible"
          :title="$t('pages.businessGuide.financing.addDialog.guide.investment.title')"
          :text="$t('pages.businessGuide.financing.addDialog.guide.investment.text')"
          @click="onToggleDialogGuide"
        />
        <!-- Info Guide -->

        <!-- Info Guide -->
        <info-guide-add-dialog
          v-if="form.type === 'loan'"
          class="mb-5"
          :visible="guideVisible"
          :title="$t('pages.businessGuide.financing.addDialog.guide.loan.title')"
          :text="$t('pages.businessGuide.financing.addDialog.guide.loan.text')"
          @click="onToggleDialogGuide"
        />
        <!-- Info Guide -->

        <!-- Info Guide -->
        <info-guide-add-dialog
          v-if="form.type === 'loc'"
          class="mb-5"
          :visible="guideVisible"
          :title="$t('pages.businessGuide.financing.addDialog.guide.lineOfCredit.title')"
          :text="$t('pages.businessGuide.financing.addDialog.guide.lineOfCredit.text')"
          @click="onToggleDialogGuide"
        />
        <!-- Info Guide -->

        <!-- Inner Wrapper -->
        <div class="inner-wrapper">
          <div class="grid-wrapper">

            <el-row :gutter="60">

              <!-- Left Side -->
              <el-col :md="12">

                <!-- Form Wrapper -->
                <div class="startup-form-wrapper">

                  <!-- Form Inner -->
                  <div class="startup-form-inner-top">

                    <!-- Form -->
                    <el-form :model="form">

                      <!-- Select Financing Option -->
                      <el-form-item
                        class="input-inside-label icon"
                        :label="$t('pages.businessGuide.financing.addDialog.fields.selectFinancingOption')"
                        :class="{ 'focused' : focus.type === true}"
                      >

                        <!-- Symbol -->
                        <span class="symbol" :class="form.type" />
                        <!-- /Symbol -->

                        <!-- Financing Option Type Select -->
                        <ib-select
                          v-model="form.typeNiceName"
                          :popper-append-to-body="false"
                          popper-class="promo-dropdown"
                          allow-create
                          placeholder=" "
                          @change="changeType"
                        >

                          <!-- Financing Option Type Options -->
                          <ib-option
                            v-for="type in options.types"
                            :key="type.value"
                            :label="type.label"
                            :value="type.value"
                          >
                            <div class="detailed-option" :class="{ 'selected': type.value === form.type }">

                              <!-- Symbol & Label -->
                              <div class="flex">

                                <!-- Symbol -->
                                <img :src="type.imgPath" svg-inline>
                                <!-- /Symbol -->

                                <!-- Label -->
                                <span
                                  class="label"
                                  :class="{'selected': type.value === form.type}"
                                >
                                  {{ type.label }}
                                </span>
                                <!-- /Label -->

                              </div>
                              <!-- Symbol & Label -->

                            </div>
                          </ib-option>
                          <!-- /Financing Option Type Options -->

                        </ib-select>
                        <!-- /Financing Option Type Select -->

                      </el-form-item>
                      <!-- /Select Financing Option -->

                      <!-- Name -->
                      <ib-input
                        v-model="form.name"
                        :label="$t('pages.businessGuide.financing.addDialog.fields.name')"
                      />
                      <!-- /Name -->

                      <!-- Amount -->
                      <el-form-item v-if="showFirstAmount" class="currency-related-form-item" :label="$t('pages.businessGuide.financing.addDialog.fields.amount')">
                        <span class="currency-symbol">{{ currencySymbol }}</span>
                        <div class="currency-related-input percentage el-input">
                          <currency-input
                            v-model="form.amount"
                            class="el-input__inner"
                            :distraction-free="false"
                            :currency="null"
                            :value-range="{min: 0, max: 999999999}"
                            :precision="{min: 0, max: 2}"
                            locale="en-US"
                          />
                        </div>
                      </el-form-item>
                      <!-- /Amount -->

                      <!-- One Time Or Installments -->
                      <div v-if="showOneTimeInstallments" class="switch-wrapper">
                        <!-- Switch -->
                        <form-switcher
                          v-model="form.oneTime"
                          :options="[
                            {
                              title: $t('pages.businessGuide.financing.addDialog.fields.options.investment.oneTime'),
                              value: true,
                              callback: changeToOneTime
                            },
                            {
                              title: $t('pages.businessGuide.financing.addDialog.fields.options.investment.installments'),
                              value: false,
                              callback: changeToInstallments
                            }
                          ]"
                        />
                        <!-- /Switch -->
                      </div>
                      <!-- /One Time Or Installments -->

                      <!-- Startup Form Inner Bottom -->
                      <div
                        class="startup-form-inner-bottom"
                        :style="{ 'marginTop': form.type === 'investment' ? '20px' : '0' }"
                      >

                        <!-- Nº of installments & Payment Method -->
                        <div
                          v-if="showOneTimeInstallments"
                          class="input-group"
                          :class="{ 'visible': form.oneTime === false }"
                        >

                          <!-- Nº of installments -->
                          <el-form-item
                            class="input-inside-label mr-lg-2"
                            :label="$t('pages.businessGuide.financing.addDialog.fields.options.investment.noOfInstallments')"
                            :class="{ 'focused': focus.numberOfInstallments === true }"
                          >
                            <div class="count el-input">
                              <currency-input
                                ref="installmentsInput"
                                v-model="form.numberOfInstallments"
                                class="el-input__inner"
                                :distraction-free="false"
                                :currency="null"
                                :value-range="{min: 1, max: maximumNumberOfInstallments()}"
                                :precision="0"
                                locale="en-US"
                                @focus="toggleFocus('numberOfInstallments')"
                                @blur="toggleFocus('numberOfInstallments')"
                              />
                            </div>

                          </el-form-item>
                          <!-- /Nº of installments -->

                          <!-- Payment Method -->
                          <el-form-item
                            class="input-inside-label payment"
                            :label="$t('pages.businessGuide.financing.addDialog.fields.options.investment.payment.title')"
                            :class="{ 'focused' : focus.paymentMethod === true }"
                          >
                            <ib-select
                              v-model="form.paymentMethod"
                              :filterable="false"
                              placeholder="  "
                              popper-class="promo-dropdown"
                              :popper-append-to-body="false"
                              @change="changePaymentMethod"
                            >
                              <ib-option
                                v-for="paymentMethod in options.paymentMethods"
                                :key="paymentMethod.key"
                                :label="paymentMethod.label"
                                :value="paymentMethod.value"
                              />
                            </ib-select>
                          </el-form-item>
                          <!-- /Payment Method -->

                        </div>
                        <!-- /Nº of installments & Payment Method -->

                        <!-- When Will It Occur -->
                        <el-form-item
                          v-if="form.type"
                          class="input-inside-label yellow"
                          :label="dateTitle"
                          :class="{ 'focused': focus.date === true }"
                        >
                          <el-date-picker
                            v-model="form.date"
                            :clearable="false"
                            :editable="false"
                            :picker-options="pickerOptions"
                            :popper-append-to-body="false"
                            format="MMMM, yyyy"
                            class="date-picker-monthly"
                            popper-class="date-picker-monthly-dropdown-modal"
                            type="month"
                            value-format="yyyy-MM-dd"
                            @blur="toggleFocus('date')"
                            @change="changeDate"
                            @focus="toggleFocus('date')"
                          />
                        </el-form-item>
                        <!-- /When Will It Occur -->

                        <!-- Advanced Settings -->
                        <!-- <el-button v-if="showOneTimeInstallments" class="advanced">-->
                        <!--   Advanced Settings <sup>SOON</sup>-->
                        <!-- </el-button>-->
                        <!-- /Advanced Settings -->

                      </div>
                      <!-- /Startup Form Inner Bottom -->

                      <!-- Amount -->
                      <el-form-item v-if="showSecondAmount" class="currency-related-form-item" :label="$t('pages.businessGuide.financing.addDialog.fields.amount')">
                        <span class="currency-symbol">{{ currencySymbol }}</span>
                        <div class="currency-related-input percentage el-input">
                          <currency-input
                            v-model="form.amount"
                            class="el-input__inner"
                            :distraction-free="false"
                            :currency="null"
                            :value-range="{min: 0, max: 999999999}"
                            :precision="{min: 0, max: 2}"
                            locale="en-US"
                          />
                        </div>
                      </el-form-item>
                      <!-- /Amount -->

                      <!-- Loan Amount -->
                      <el-form-item v-if="form.type === 'loan'" class="currency-related-form-item" :label="$t('pages.businessGuide.financing.addDialog.fields.amount')">
                        <span class="currency-symbol">{{ currencySymbol }}</span>
                        <div class="currency-related-input percentage el-input">
                          <currency-input
                            v-model="form.amount"
                            class="el-input__inner"
                            :distraction-free="false"
                            :currency="null"
                            :value-range="{min: 0, max: 100000000}"
                            :precision="{min: 0, max: 2}"
                            locale="en-US"
                          />
                        </div>
                      </el-form-item>
                      <!-- /Loan Amount -->

                    </el-form>
                    <!-- /Form -->

                  </div>
                  <!-- /Form Inner -->

                  <!-- Loans Block -->
                  <div v-if="form.type === 'loan'">

                    <!-- Loans Sliders -->
                    <div v-for="loan in options.loans" :key="loan.name" class="slider-block editable">

                      <!-- Title & Value -->
                      <div class="top">

                        <!-- Title -->
                        <p>{{ loan.title }}</p>
                        <!-- /Title -->

                        <!-- Value -->
                        <div class="input-edit">
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" v-if="loan.editable" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#fff" fill-rule="evenodd" d="M1.5 12.78l8.34-8.575 2.14 2.197-8.35 8.56H1.5v-2.183zM12.125 1.853l2.15 2.203-1.23 1.26L10.9 3.119l1.225-1.265zM.75 16.498h3.19c.2 0 .39-.082.53-.226L15.865 4.6a.777.777 0 000-1.086L12.655.225a.74.74 0 00-.53-.225.74.74 0 00-.53.225L.22 11.92a.796.796 0 00-.22.543v3.268c0 .425.335.768.75.768z"/></svg>
                          <p>{{ getLoanNiceLabel(loan.name, form[loan.name]) }}</p>
                          <el-input v-if="loan.editable" class="borderless-input" placeholder="" />
                        </div>
                        <!-- /Value -->

                      </div>
                      <!-- /Title & Value -->

                      <!-- Slider -->
                      <div class="bottom">
                        <el-slider
                          v-model="form[loan.name]"
                          :show-tooltip="false"
                          :min="loan.min"
                          :max="loan.max"
                          :step="typeof loan.step !== 'undefined' ? loan.step : 1"
                          @input="getLoanNiceLabel(loan.name, form[loan.name])"
                        />
                      </div>
                      <!-- /Slider -->

                    </div>
                    <!-- /Loans Sliders -->

                    <!-- Loan Results -->
                    <div class="results-block">

                      <!-- Borrowing, Interest & Total Repayment -->
                      <div class="flex">

                        <!-- Borrowing -->
                        <div class="result">
                          <p class="bold">
                            {{ currencySymbol + '' + getFormattedAmount(form.amount, 0) }}
                          </p>
                          <p>{{ $t('pages.businessGuide.financing.addDialog.fields.options.loan.borrowing') }}</p>
                        </div>
                        <!-- /Borrowing -->

                        <!-- Interest -->
                        <div class="result">
                          <p class="bold">
                            {{ getLoanInterest() }}
                          </p>
                          <p>{{ $t('pages.businessGuide.financing.addDialog.fields.options.loan.interest') }}</p>
                        </div>
                        <!-- /Interest -->

                        <!-- Total Repayment -->
                        <div class="result">
                          <p class="bold">
                            {{ getLoanTotalRepayment() }}
                          </p>
                          <p>{{ $t('pages.businessGuide.financing.addDialog.fields.options.loan.totalRepayment') }}</p>
                        </div>
                        <!-- Total Repayment -->

                      </div>
                      <!-- /Borrowing, Interest & Total Repayment -->

                      <!-- Monthly Repayment -->
                      <div class="result full">
                        <p class="bold">
                          {{ getLoanMonthlyRepayment() }}
                        </p>
                        <p>{{ $t('pages.businessGuide.financing.addDialog.fields.options.loan.monthlyRepayment') }}</p>
                      </div>
                      <!-- /Monthly Repayment -->

                    </div>
                    <!-- /Loan Results -->

                  </div>
                  <!-- /Loans Block -->

                  <!-- Lines Of Credit Block -->
                  <div v-if="form.type === 'loc'">

                    <!-- Lines Of Credit Sliders -->
                    <div
                      v-for="lineOfCredit in options.linesOfCredit"
                      :key="lineOfCredit.name"
                      class="slider-block editable"
                    >

                      <!-- Title & Value -->
                      <div class="top">

                        <!-- Title -->
                        <p>{{ lineOfCredit.title }}</p>
                        <!-- /Title -->

                        <!-- Value -->
                        <div class="input-edit">
                          <p>{{ getCreditNiceLabel(lineOfCredit.name, form[lineOfCredit.name]) }}</p>
                        </div>
                        <!-- /Value -->

                      </div>
                      <!-- /Title & Value -->

                      <!-- Slider -->
                      <div
                        v-if="['period', 'averageWithdrawal', 'howManyTimes', 'paymentFrequency'].indexOf(lineOfCredit.name) === -1"
                        class="bottom"
                      >
                        <el-slider
                          v-model="form[lineOfCredit.name]"
                          :show-tooltip="false"
                          :min="lineOfCredit.min"
                          :max="lineOfCredit.max"
                          :step="typeof lineOfCredit.step !== 'undefined' ? lineOfCredit.step : 1"
                          @input="getCreditNiceLabel(lineOfCredit.name, form[lineOfCredit.name])"
                        />
                      </div>
                      <!-- /Slider -->

                      <!-- Slider Period -->
                      <div v-if="lineOfCredit.name === 'period'" class="bottom">
                        <el-slider
                          v-model="form[lineOfCredit.name]"
                          :show-tooltip="false"
                          :min="lineOfCredit.min"
                          :max="maxCreditPeriod"
                          :step="typeof lineOfCredit.step !== 'undefined' ? lineOfCredit.step : 1"
                          @input="getCreditNiceLabel(lineOfCredit.name, form[lineOfCredit.name])"
                        />
                      </div>
                      <!-- /Slider Period -->

                      <!-- Slider Average Withdrawal -->
                      <div v-if="lineOfCredit.name === 'averageWithdrawal'" class="bottom">
                        <el-slider
                          v-model="form[lineOfCredit.name]"
                          :show-tooltip="false"
                          :min="lineOfCredit.min"
                          :max="maxAverageWithdrawal"
                          :step="typeof lineOfCredit.step !== 'undefined' ? lineOfCredit.step : 1"
                          @input="getCreditNiceLabel(lineOfCredit.name, form[lineOfCredit.name])"
                        />
                      </div>
                      <!-- /Slider Average Withdrawal -->

                      <!-- Slider Payment Frequency -->
                      <div v-if="lineOfCredit.name === 'paymentFrequency'" class="bottom">
                        <el-slider
                          v-model="form[lineOfCredit.name]"
                          :show-tooltip="false"
                          :disabled="maxPaymentFrequency === 1"
                          :min="minPaymentFrequency"
                          :max="maxPaymentFrequency"
                          :step="typeof lineOfCredit.step !== 'undefined' ? lineOfCredit.step : 1"
                          @input="getCreditNiceLabel(lineOfCredit.name, form[lineOfCredit.name])"
                        />
                      </div>
                      <!-- /Slider Payment Frequency -->

                      <!-- Slider How Many Times -->
                      <div v-if="lineOfCredit.name === 'howManyTimes'" class="bottom">
                        <el-slider
                          v-model="form[lineOfCredit.name]"
                          :show-tooltip="false"
                          :min="lineOfCredit.min"
                          :max="maxHowManyTimes"
                          :step="typeof lineOfCredit.step !== 'undefined' ? lineOfCredit.step : 1"
                          @input="getCreditNiceLabel(lineOfCredit.name, form[lineOfCredit.name])"
                        />
                      </div>
                      <!-- /Slider How Many Times -->

                    </div>
                    <!-- /Lines Of Credit Sliders -->

                    <!-- Line Of Credit Results -->
                    <div class="results-block">
                      <div class="flex">
                        <div class="result">
                          <p class="bold">
                            {{ getCreditAverageWithdrawal() }}
                          </p>
                          <p>{{ $t('pages.businessGuide.financing.addDialog.fields.options.lineOfCredit.averageWithdrawalTitle') }}</p>
                        </div>
                        <div class="result">
                          <p class="bold">
                            {{ getCreditMonthlyInterest() }}
                          </p>
                          <p>{{ $t('pages.businessGuide.financing.addDialog.fields.options.lineOfCredit.monthlyInterestTitle') }}</p>
                        </div>
                        <div class="result">
                          <p class="bold">
                            {{ getCreditTotalInterest() }}
                          </p>
                          <p>{{ $t('pages.businessGuide.financing.addDialog.fields.options.lineOfCredit.totalInterestTitle') }}</p>
                        </div>
                      </div>
                    </div>
                    <!-- /Line Of Credit Results -->

                  </div>
                  <!-- /Lines Of Credit Block -->

                </div>
                <!-- /Form Wrapper -->

                <!-- Dialog Bottom -->
                <div class="my-4 my-md-0">

                  <!-- Divider -->
                  <ib-divider block class="my-4" />
                  <!-- /Divider -->

                  <el-row :gutter="10" class="d-flex">
                    <el-col :span="12">
                      <!-- Close -->
                      <ib-button class="w-100" variant="white" size="lg" font-family="regular" @click="closeDialogOnButton">
                        {{ $t('close') }}
                      </ib-button>
                      <!-- /Close -->
                    </el-col>

                    <el-col :span="12">
                      <ib-button
                        class="w-100 text-uppercase"
                        size="lg"
                        :disabled="!isAddEnabled"
                        :loading="loading"
                        @click="saveFinance"
                      >
                        {{ form.id === 0 ? $t('add') : $t('save') }}
                      </ib-button>
                    </el-col>
                  </el-row>
                </div>
                <!-- /Dialog Bottom -->

              </el-col>
              <!-- /Left Side -->

              <!-- Right Side -->
              <el-col :md="12">
                <add-dialog-entry-header>
                  <template #left>
                    <h2>{{ $tc('financingOption', 2) }}</h2>
                  </template>
                  <template #right>
                    <erase-icon-button
                      v-if="removeButtonVisible"
                      confirm
                      :loading="removeLoadingExamples"
                      @confirm="deleteExamples"
                    />
                  </template>
                </add-dialog-entry-header>

                <!-- Financing Options Added -->
                <div ref="cardHolder" class="costs-added">

                  <!-- Financing Options Added List -->
                  <div
                    v-for="finance in $store.state.idea.storyMode.setUp.financing.finances"
                    :key="finance.id"
                  >
                    <set-up-and-projections-card
                      class="mb-2"
                      :entity="finance"
                      :name="finance.name"
                      :date="getFormattedDate(finance.date)"
                      :amount="getAmount(finance)"
                      :type="finance.type"
                      view="list"
                      :delete-disabled="form.id === finance.id"
                      :edit-disabled="form.id === finance.id"
                      @edit="onEditFinance"
                      @copy="onCopyFinance"
                      @delete="onDeleteFinance"
                    />
                  </div>
                  <!-- /Financing Options Added List -->

                </div>
                <!-- /Financing Options Added -->

              </el-col>
              <!-- /Right Side -->

            </el-row>
          </div>
        </div>
        <!-- /Inner Wrapper -->

      </ib-dialog-container>

      <!-- /Content Wrapper -->

    </ib-dialog>
  </div>
  <!-- /Dialog Add Financing -->

</template>

<script>
import AddDialogEntryHeader from '@/components/_v2/Common/AddDialogEntryHeader'
import AddDialogHeader from '@/components/_v2/Common/AddDialogHeader'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import FormSwitcher from '@/components/_v2/Form/FormSwitcher'
import IbOption from '@/components/_v2/Select/IbOption'
import InfoGuideAddDialog from '@/views/Home/StoryMode/Components/Guide/InfoGuideAddDialog.vue'
import MixinCloseMenu from '@/mixins/closeDropdownMenuOnScroll'
import MixinCurrencies from '@/mixins/currencies'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinDialog from '@/mixins/dialog'
import MixinFinancing from '@/mixins/financing'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import SetUpAndProjectionsCard from '@/views/Home/StoryMode/Components/Cards/SetUpAndProjectionsCard'

import { mapActions, mapGetters } from 'vuex'
import _find from 'lodash/find'
import pluralize from 'pluralize'

export default {
  name: 'DialogAddFinancing',

  components: {
    InfoGuideAddDialog,
    EraseIconButton,
    AddDialogEntryHeader,
    AddDialogHeader,
    FormSwitcher,
    IbOption,
    SetUpAndProjectionsCard
  },

  mixins: [
    MixinCurrencies,
    MixinDialog,
    MixinFinancing,
    MixinCloseMenu,
    MixinGuide,
    MixinDeleteIdeaExamples,
    MixinIdeaRoles
  ],

  props: {
    dialogAddFinancingVisible: {
      type: Boolean,
      default: false
    },
    financeAction: {
      type: Object,
      default: null
    }
  },

  data () {
    const maxOccurDate = this.$moment(this.$store.state.idea.ideaSettings.startingFrom).add(this.$store.state.idea.ideaSettings.planningFor, 'Y').format('x')
    const minOccurDate = this.$moment(this.$store.state.idea.ideaSettings.startingFrom).format('x')

    return {
      loading: false,
      removeLoadingExamples: false,
      guideVisible: false,
      form: {
        id: 0,
        ideaId: this.$store.state.idea.id,
        name: '',
        type: '',
        typeNiceName: '',
        amount: null,
        oneTime: null,
        numberOfInstallments: null,
        paymentMethod: '',
        date: this.$store.state.idea.ideaSettings.startingFrom,
        loanAmount: 1,
        repaymentPeriod: 1,
        annualInterestRate: 0,
        gracePeriod: 0,
        creditLimit: 0,
        period: 0,
        interestRate: 0,
        averageWithdrawal: 0,
        averageWithdrawalAmount: 0,
        paymentFrequency: 1,
        howManyTimes: 0
      },
      focus: {
        type: false,
        numberOfInstallments: false,
        paymentMethod: false,
        date: false
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < minOccurDate || time.getTime() >= maxOccurDate
        }
      },
      options: {
        types: [
          {
            id: 1,
            label: this.$t('pages.businessGuide.financing.addDialog.fields.options.savings.title'),
            value: 'savings',
            imgPath: require('@/assets/img/icons/financing-dropdown/savings-circle.svg')
          },
          {
            id: 3,
            label: this.$t('pages.businessGuide.financing.addDialog.fields.options.crowdfunding.title'),
            value: 'crowdfunding',
            imgPath: require('@/assets/img/icons/financing-dropdown/crowdfunding-circle.svg')
          },
          {
            id: 4,
            label: this.$t('pages.businessGuide.financing.addDialog.fields.options.investment.title'),
            value: 'investment',
            imgPath: require('@/assets/img/icons/financing-dropdown/investment-circle.svg')
          },
          {
            id: 2,
            label: this.$t('pages.businessGuide.financing.addDialog.fields.options.loan.title'),
            value: 'loan',
            imgPath: require('@/assets/img/icons/financing-dropdown/loan-circle.svg')
          },
          {
            id: 5,
            label: this.$t('pages.businessGuide.financing.addDialog.fields.options.lineOfCredit.title'),
            value: 'loc',
            imgPath: require('@/assets/img/icons/financing-dropdown/line-of-credit-circle.svg')
          }
        ],
        paymentMethods: [
          {
            key: 1,
            label: this.$t('pages.businessGuide.financing.addDialog.fields.options.investment.payment.paidMonthly'),
            value: 'monthly'
          },
          {
            key: 2,
            label: this.$t('pages.businessGuide.financing.addDialog.fields.options.investment.payment.paidQuarterly'),
            value: 'quarterly'
          },
          {
            key: 3,
            label: this.$t('pages.businessGuide.financing.addDialog.fields.options.investment.payment.paidAnnually'),
            value: 'annually'
          }
        ],
        linesOfCredit: [
          {
            title: this.$t('pages.businessGuide.financing.addDialog.fields.options.lineOfCredit.creditLimit'),
            name: 'creditLimit',
            min: 1,
            max: 154,
            step: 1,
            editable: false
          },
          {
            title: this.$t('pages.businessGuide.financing.addDialog.fields.options.lineOfCredit.forWhichPeriod'),
            name: 'period',
            min: 1,
            step: 1,
            editable: false
          },
          {
            title: this.$t('pages.businessGuide.financing.addDialog.fields.options.lineOfCredit.annualInterestRate'),
            name: 'interestRate',
            min: 0,
            max: 100,
            step: 0.5,
            editable: false
          },
          {
            title: this.$t('pages.businessGuide.financing.addDialog.fields.options.lineOfCredit.averageWithdrawal'),
            name: 'averageWithdrawal',
            min: 0,
            step: 1,
            editable: false
          },
          {
            title: this.$t('pages.businessGuide.financing.addDialog.fields.options.lineOfCredit.paymentFrequency'),
            name: 'paymentFrequency',
            min: 0,
            step: 1,
            editable: false
          },
          {
            title: this.$t('pages.businessGuide.financing.addDialog.fields.options.lineOfCredit.numberOfWithdrawals'),
            name: 'howManyTimes',
            min: 0,
            step: 1,
            editable: false
          }
        ],
        loans: [
          {
            title: this.$t('pages.businessGuide.financing.addDialog.fields.options.loan.repaymentPeriod'),
            name: 'repaymentPeriod',
            min: 1,
            max: 120,
            editable: false
          },
          {
            title: this.$t('pages.businessGuide.financing.addDialog.fields.options.loan.annualInterestRate'),
            name: 'annualInterestRate',
            min: 0,
            max: 100,
            step: 0.5,
            editable: false
          },
          {
            title: this.$t('pages.businessGuide.financing.addDialog.fields.options.loan.gracePeriod'),
            name: 'gracePeriod',
            min: 0,
            max: 0,
            editable: false
          }
        ]
      }
    }
  },

  computed: {
    ...mapGetters('idea', ['getHasItemsFromTemplate']),

    isAddEnabled () {
      if (!this.form.type) {
        return false
      }

      if (['savings', 'crowdfunding', 'investment', 'loan'].indexOf(this.form.type) !== -1) {
        if (!this.form.name || !this.form.amount) {
          return false
        }
      }

      if (this.form.type === 'investment' && !this.form.oneTime && this.form.numberOfInstallments === null) {
        return false
      }

      if (this.form.type === 'loan') {
        if (!this.form.name) {
          return false
        }
      }

      if (this.form.type === 'loc') {
        if (!this.form.name || !this.form.averageWithdrawalAmount || !this.form.howManyTimes) {
          return false
        }
      }

      return true
    },

    showFirstAmount () {
      return this.form.type === 'investment'
    },

    showOneTimeInstallments () {
      return this.form.type === 'investment'
    },

    dateTitle () {
      if (this.form.type === 'savings' || this.form.type === 'loan' || this.form.type === 'loc') {
        return this.$t('pages.businessGuide.financing.addDialog.fields.starting')
      }

      if (this.form.type === 'crowdfunding') {
        return this.$t('pages.businessGuide.financing.addDialog.fields.startingFrom')
      }

      return this.$t('pages.businessGuide.financing.addDialog.fields.whenItWillOccur')
    },

    showSecondAmount () {
      return this.form.type === 'savings' || this.form.type === 'crowdfunding'
    },

    maxCreditPeriod () {
      const planningFor = this.$store.state.idea.ideaSettings.planningFor
      const startingString = this.$store.state.idea.ideaSettings.startingFrom
      const endingMoment = this.$moment(startingString).add(planningFor, 'Y')
      const dateMoment = this.$moment(this.form.date)

      return endingMoment.diff(dateMoment, 'M', true)
    },

    maxAverageWithdrawal () {
      return this.form.creditLimit
    },

    minPaymentFrequency () {
      return this.maxPaymentFrequency === 1 ? 0 : 1
    },

    maxPaymentFrequency () {
      if (this.form.period === 2) {
        return 2
      }

      if (this.form.period >= 3 && this.form.period < 6) {
        return 3
      }

      if (this.form.period >= 6 && this.form.period < 12) {
        return 4
      }

      if (this.form.period >= 12) {
        return 5
      }

      return 1
    },

    maxHowManyTimes () {
      let repaymentPeriod = this.form.paymentFrequency

      // Repayment period 6 months
      if (this.form.paymentFrequency === 4) {
        repaymentPeriod = 6
      }
      // Repayment period 12 months
      if (this.form.paymentFrequency === 5) {
        repaymentPeriod = 12
      }

      const validity = this.form.period / repaymentPeriod
      const maxWithdrawalInPeriod = validity * this.form.amount

      if (maxWithdrawalInPeriod === 0 || this.form.averageWithdrawalAmount === 0) {
        return 0
      }

      if (Math.floor(maxWithdrawalInPeriod / this.form.averageWithdrawalAmount) > this.form.period) {
        return this.form.period
      }

      return Math.floor(maxWithdrawalInPeriod / this.form.averageWithdrawalAmount)
    },

    isDialogOpen () {
      return this.dialogAddFinancingVisible
    },

    removeButtonVisible () {
      return this.canEdit && this.getHasItemsFromTemplate('getFinances')
    }
  },

  watch: {
    'form.repaymentPeriod': function (val) {
      // eslint-disable-next-line array-callback-return
      this.options.loans.map((loan) => {
        if (loan.name === 'gracePeriod') {
          loan.max = val > 1 ? val - 1 : 0
        }
      })
    }
  },

  methods: {
    ...mapActions('idea', [
      'deleteStoryModeFinance',
      'deleteIdeaExamples'
    ]),

    closeDialogOnButton () {
      this.$emit('close-dialog-on-button')
    },

    closeDialog () {
      this.$emit('close-dialog-add-financing')
    },

    closedDialog () {
      this.resetForm()
      this.guideVisible = false
    },

    openDialog () {
      this.openGuideDialog('addFinancingGuide')
      if (this.financeAction) {
        this.copyFinanceToForm(this.financeAction)
        this.form.ideaId = this.$store.state.idea.id
      }
      this.checkInputLabels()
    },

    onToggleDialogGuide () {
      this.guideVisible = !this.guideVisible
    },

    toggleFocus (input) {
      this.focus[input] = !this.focus[input]

      if (this.form[input] !== null && this.form[input] !== '') {
        this.focus[input] = true
      }
    },

    resetForm () {
      this.form = {
        id: 0,
        ideaId: this.$store.state.idea.id,
        name: '',
        type: '',
        typeNiceName: '',
        amount: null,
        oneTime: null,
        numberOfInstallments: null,
        paymentMethod: '',
        date: this.$store.state.idea.ideaSettings.startingFrom,
        loanAmount: 1,
        repaymentPeriod: 1,
        annualInterestRate: 0,
        gracePeriod: 0,
        creditLimit: 0,
        period: 0,
        interestRate: 0,
        averageWithdrawal: 0,
        averageWithdrawalAmount: 0,
        paymentFrequency: 1,
        howManyTimes: 0
      }
      this.focus = {
        type: false,
        name: false,
        numberOfInstallments: false,
        paymentMethod: false,
        date: false
      }
      this.checkInputLabels()
    },

    resetGuide () {
      if (this.$store.getters['idea/getFinances'].length === 0) {
        this.guideVisible = false
      }
    },

    saveFinance () {
      this.loading = true
      if (this.form.id === 0) {
        this.addFinance()
      } else {
        this.updateFinance()
      }
    },

    addFinance () {
      this.$http.post('story-mode/set-up/financing/finances', this.form)
        .then((response) => {
          if (response.status === 201) {
            this.resetGuide()
            this.$store.commit('idea/addFinance', response.data.payload.financing)
            this.resetForm()
            this.scrollToTop()
          }
          this.$emit('on-update-financing-view')
        }).finally(() => {
          this.loading = false
        })
    },

    updateFinance () {
      this.$http.put(`story-mode/set-up/financing/finances/${this.form.id}`, this.form)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit('idea/updateFinance', response.data.payload.financing)
            this.resetForm()
            this.scrollToTop()
          }
          this.$emit('on-update-financing-view')
        }).finally(() => {
          this.loading = false
        })
    },

    onEditFinance (finance) {
      this.copyFinanceToForm(finance)
    },

    onCopyFinance (finance) {
      this.copyFinanceToForm(finance)
      this.form.id = 0
    },

    copyFinanceToForm (finance) {
      this.resetForm()
      this.form = Object.assign({}, finance)
      this.form.ideaId = this.$store.state.idea.id
      this.form.typeNiceName = _find(this.options.types, ['value', finance.type]).label

      if (this.form.type !== 'investment') {
        this.form.oneTime = null
      }
      if (this.form.type === 'loan') {
        this.form.loanAmount = this.getStepAmount(this.form.amount)
      }
      if (this.form.type === 'loc') {
        this.form.creditLimit = this.getStepAmount(this.form.amount)
        this.form.averageWithdrawal = this.getStepAmount(this.form.averageWithdrawalAmount)
      }
      this.checkInputLabels()
      this.scrollToTop()
    },

    onDeleteFinance (finance) {
      this.deleteStoryModeFinance(finance.id)
        .then((response) => {
          this.$emit('on-update-financing-view')
        })
    },

    checkInputLength (input) {
      this.focus[input] = !!this.form[input]
    },

    checkInputLabels () {
      this.checkInputLength('type')
      this.checkInputLength('numberOfInstallments')
      this.checkInputLength('paymentMethod')
      this.checkInputLength('name')
      this.checkInputLength('date')
    },

    changeDate () {
      this.toggleFocus('date')
    },

    changeType (value) {
      this.resetForm()
      for (let i = 0; i < this.options.types.length; i++) {
        if (value === this.options.types[i].value) {
          this.form.type = this.options.types[i].value
          this.form.name = this.options.types[i].label
          this.form.typeNiceName = this.form.name
          this.toggleFocus('type')
        } else {
          this.form.type = value
          this.toggleFocus('type')
        }
      }
      this.form.type = value
      this.form.amount = (this.form.type === 'load' || this.form.type === 'loc') ? 100 : null
      this.form.oneTime = this.form.type === 'investment' ? true : null
      this.checkInputLength('type')
    },

    maximumNumberOfInstallments () {
      if (this.form.paymentMethod) {
        return this.$store.state.idea.ideaSettings.planningFor * 12 / this.getNumberOfMonthsByInterval(this.form.paymentMethod)
      }

      return 1000
    },

    changeToOneTime () {
      this.form.numberOfInstallments = null
      this.form.paymentMethod = ''
      this.checkInputLength('numberOfInstallments')
    },

    changeToInstallments () {
      this.form.paymentMethod = 'monthly'
      this.checkInputLength('paymentMethod')
      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        this.$refs.installmentsInput.focus
      }, 100)
    },

    getFormattedDate (date) {
      return this.$moment(date).format('MMM YYYY')
    },

    changePaymentMethod () {
      this.checkInputLength('numberOfInstallments')
    },

    getStepAmount (amount) {
      if (amount <= 1000) {
        return amount / 100
      } else if (amount > 1000 && amount <= 100000) {
        return amount / 1000 + 9
      } else if (amount > 100000 && amount <= 500000) {
        return amount / 10000 + 99
      } else {
        return amount / 100000 + 144
      }
    },

    getLoanNiceLabel (name, value) {
      if (name === 'repaymentPeriod') {
        return value + ' ' + pluralize('Month', value)
      }

      if (name === 'annualInterestRate') {
        return value + '%'
      }

      if (name === 'gracePeriod') {
        return value + ' ' + pluralize('Month', value)
      }
    },

    getLoanInterest () {
      const loanCalculation = this.calculateLoanOld(this.form.amount, this.form.annualInterestRate, this.form.repaymentPeriod, this.form.gracePeriod)

      return this.currencySymbol + '' + this.getFormattedAmount(loanCalculation.totalInterest)
    },

    getLoanTotalRepayment () {
      const loanCalculation = this.calculateLoanOld(this.form.amount, this.form.annualInterestRate, this.form.repaymentPeriod, this.form.gracePeriod)

      return this.currencySymbol + '' + this.getFormattedAmount(loanCalculation.totalPayment)
    },

    getLoanMonthlyRepayment () {
      const loanCalculation = this.calculateLoanOld(this.form.amount, this.form.annualInterestRate, this.form.repaymentPeriod, this.form.gracePeriod)

      return this.currencySymbol + '' + this.getFormattedAmount(loanCalculation.monthlyPayment)
    },

    getCreditNiceLabel (name, value) {
      if (name === 'creditLimit') {
        if (value <= 10) {
          this.form.amount = value * 100
        } else if (value > 10 && value < 110) {
          this.form.amount = (value - 9) * 1000
        } else if (value >= 110 && value <= 149) {
          this.form.amount = (value - 99) * 10000
        } else {
          this.form.amount = (value - 144) * 100000
        }

        return this.currencySymbol + '' + this.getFormattedAmount(this.form.amount, 0)
      }

      if (name === 'period') {
        return value + ' ' + pluralize('Month', value)
      }

      if (name === 'interestRate') {
        return value + '%'
      }

      if (name === 'averageWithdrawal') {
        if (value <= 10) {
          this.form.averageWithdrawalAmount = value * 100
        } else if (value > 10 && value < 110) {
          this.form.averageWithdrawalAmount = (value - 9) * 1000
        } else if (value >= 110 && value <= 149) {
          this.form.averageWithdrawalAmount = (value - 99) * 10000
        } else {
          this.form.averageWithdrawalAmount = (value - 144) * 100000
        }

        return this.currencySymbol + '' + this.getFormattedAmount(this.form.averageWithdrawalAmount, 0)
      }

      if (name === 'paymentFrequency') {
        const frequency = ['Monthly', 'Bi-Monthly', 'Quarterly', 'Semi-annual', 'Annual']

        return frequency[value - 1]
      }

      if (name === 'howManyTimes') {
        this.form.howManyTimes = isNaN(value) ? 0 : value
      }

      return value
    },

    getCreditAverageWithdrawal () {
      return this.currencySymbol + '' + this.getFormattedAmount(this.form.averageWithdrawalAmount)
    },

    getCreditMonthlyInterest () {
      const creditMonthlyInterest = this.calculateCreditMonthlyInterest(this.form.averageWithdrawalAmount, this.form.interestRate)

      return this.currencySymbol + '' + this.getFormattedAmount(creditMonthlyInterest)
    },

    getCreditTotalInterest () {
      const creditTotalInterest = this.calculateCreditTotalInterest(this.form.averageWithdrawalAmount, this.form.interestRate, this.form.howManyTimes, this.getPaymentFrequencyInMonths(this.form.paymentFrequency))

      return this.currencySymbol + '' + this.getFormattedAmount(creditTotalInterest)
    },

    getAmount (finance) {
      if (finance.type === 'loc') {
        return this.getFormattedAmount(finance.howManyTimes * finance.averageWithdrawalAmount)
      }

      return this.getFormattedAmount(finance.amount)
    },

    getNumberOfMonthsByInterval (interval) {
      const numberOfMonths = {
        monthly: 1,
        quarterly: 3,
        annually: 12
      }

      return numberOfMonths[interval.toLowerCase()]
    },

    async deleteExamples () {
      try {
        this.removeLoadingExamples = true
        const response = await this.deleteIdeaExamples('financing')
        this.$emit('on-update-financing-view')
        const { type, ids } = response
        this.deleteFromState({ type, ids })
        this.resetForm()
        this.removeLoadingExamples = false
      } catch (error) {
        this.removeLoadingExamples = false
      }
    }
  }
}
</script>
